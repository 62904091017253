import * as React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { GTM } from 'Common/utils';
import { useViewport, useTranslation, useOrderRequestState } from 'Common/hooks';
import { Product } from 'Common/types';
import { Translation } from '../localization';
import { NumberInput, PureTip } from '../ui';
import { ProductInfo } from 'Common/models';

export default function ProductInput({
    className,
    isUserAuthenticated,
    onQuantityChange,
    quantity,
    minQuantity = 1,
    checkoutPageLink,
    currentChildren,
    onAddToCart,
    addToCartLabel = 'Form.Button.AddToCart.Label',
    currentItemStatus,
    productInfo,
    gtmListValue,
    reverseButtonOrder = false,
    numInputButtons = false,
    hideWhenOOS = false,
    disabled = false,
    allowZeroQty = false,
    noSidePadding = false,
    paddingValue = 1,
    noBuyNow = false,
    statusTooltip,
    itemCodes = [],
    children,
    productRecommendationId = null,
    totalQty = 0,
}) {
    const viewport = useViewport();
    const addToCartToolTip = useTranslation('Form.Button.AddToCart.ToolTip.Label');
    const buyNowToolTip = useTranslation('Form.Button.BuyNow.ToolTip.Label');
    const cartIsUpdating = useOrderRequestState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [addToCartTip, setAddToCartTip] = React.useState({
        timestamp: 0,
        message: null,
        hidden: true,
    });

    const isDisplayOnly = ProductInfo.isDisplayOnly(productInfo);

    const [buyNowTip, setBuyNowTip] = React.useState({
        timestamp: 0,
        message: null,
        hidden: true,
    });

    const buttonsDisabled = React.useMemo(
        () => disabled || (!allowZeroQty && (!currentItemStatus?.hasStock || !quantity)) || currentChildren.length > 1,
        [currentItemStatus, quantity, currentChildren, allowZeroQty, disabled]
    );

    const showTip = React.useCallback(
        (tip) => (viewport.is.touch && buttonsDisabled && tip ? () => alert(tip) : null),
        [viewport, buttonsDisabled]
    );

    const statusCallback = React.useCallback(
        (buyNow = false) =>
            statusTooltip
                ? (successMsg, validationIssues = [], params) => {
                      const setter = buyNow ? setBuyNowTip : setAddToCartTip;
                      const timestamp = Date.now();
                      const placement = typeof statusTooltip === 'string' ? statusTooltip : undefined;

                      if (successMsg && !buyNow) {
                          setter({ message: successMsg, params, timestamp, placement, level: 'success' });
                      } else {
                          const lineItem = validationIssues?.find(
                              (issue) => issue.RefType === 'LineItem' && itemCodes.includes(issue.Ref)
                          )?.Issue;

                          if (lineItem) setter({ message: lineItem, timestamp, placement, level: 'danger' });
                          else if (validationIssues?.length) {
                              setter({ message: validationIssues[0].Issue, timestamp, placement, level: 'warning' });
                          }
                      }
                  }
                : null,
        [statusTooltip, itemCodes]
    );

    const addItem = React.useCallback(
        (buyNow = false) =>
            onAddToCart
                ? async () => {
                      const gtmPayload = GTM.mapEntityToAddCart(
                          productInfo.Code === currentItemStatus?.code
                              ? productInfo
                              : productInfo.Children.find((c) => c.Code === currentItemStatus?.code),
                          quantity,
                          productInfo.Attributes,
                          gtmListValue,
                          totalQty + quantity
                      );

                      setIsLoading(true);
                      await onAddToCart(currentItemStatus?.code, quantity, {
                          gtmPayload,
                          rerouteLink: buyNow ? checkoutPageLink : null,
                          statusCallback: statusCallback(buyNow),
                          productRecommendationId,
                          cartApiOptions: { expand: ['forms', 'ordertotals'], update: ['lineitems', 'promotions'] },
                      });
                      setIsLoading(false);
                  }
                : null,
        [
            onAddToCart,
            productInfo,
            currentItemStatus?.code,
            quantity,
            gtmListValue,
            totalQty,
            checkoutPageLink,
            statusCallback,
            productRecommendationId,
        ]
    );

    const onBuyNow = React.useMemo(
        () => (noBuyNow || !checkoutPageLink ? null : addItem(true)),
        [noBuyNow, checkoutPageLink, addItem]
    );

    const onAddItem = React.useMemo(() => addItem(), [addItem]);

    const btnsColSize = React.useMemo(
        () => (!onBuyNow && !onAddItem ? 0 : !onQuantityChange ? 12 : !numInputButtons && !viewport.is.mobile ? 10 : 9),
        [onBuyNow, onAddItem, numInputButtons, onQuantityChange, viewport]
    );

    const p = React.useMemo(
        () => ({ left: `pl-${paddingValue}`, right: `pr-${paddingValue}`, x: `px-${paddingValue}` }),
        [paddingValue]
    );

    return (
        <div className={cx('ProductInput container mt-3 px-0', className)}>
            <div className="row justify-content-center justify-content-md-start col-12 px-0 mx-0">
                {isDisplayOnly ? null : currentItemStatus.hasStock || !hideWhenOOS ? (
                    <>
                        {onQuantityChange ? (
                            <div
                                className={cx(
                                    `num-input col-${12 - (btnsColSize || 8)}`,
                                    noSidePadding ? 'px-0' : onAddItem || onBuyNow ? `${p.left} pr-0` : p.x
                                )}
                            >
                                <NumberInput
                                    min={minQuantity}
                                    step={1}
                                    noDecimal
                                    value={quantity}
                                    onChange={onQuantityChange}
                                    useStepBtns={numInputButtons}
                                    disabled={disabled}
                                />
                            </div>
                        ) : null}
                        {!btnsColSize ? null : (
                            <div
                                className={cx(`col-${btnsColSize} px-0 d-flex align-items-center`)}
                                style={{ flexDirection: reverseButtonOrder ? 'row-reverse' : 'row' }}
                            >
                                {onAddItem ? (
                                    <div
                                        className={cx(
                                            'h-100 flex-1',
                                            !noSidePadding || (onQuantityChange && onBuyNow)
                                                ? p.x
                                                : onQuantityChange
                                                ? p.left
                                                : onBuyNow
                                                ? p.right
                                                : 'px-0'
                                        )}
                                        style={{ position: 'relative' }}
                                        onClick={showTip(addToCartToolTip)}
                                    >
                                        <PureTip
                                            timestamp={addToCartTip.timestamp}
                                            placement={addToCartTip.placement}
                                            level={addToCartTip.level}
                                            clickDismiss
                                        >
                                            <Translation id={addToCartTip.message} params={addToCartTip.params} />
                                        </PureTip>
                                        <button
                                            className={cx(
                                                'btn btn-primary w-100',
                                                buttonsDisabled ? 'disabled' : 'active'
                                            )}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={buttonsDisabled ? addToCartToolTip : null}
                                            disabled={buttonsDisabled || isLoading || cartIsUpdating}
                                            onClick={onAddItem}
                                        >
                                            <Translation id={addToCartLabel} />
                                        </button>
                                    </div>
                                ) : null}
                                {onBuyNow ? (
                                    <div
                                        className={cx(
                                            'h-100 flex-1',
                                            noSidePadding && (!onQuantityChange || onAddItem)
                                                ? 'px-0'
                                                : noSidePadding && onQuantityChange && !onAddItem
                                                ? p.left
                                                : onAddItem
                                                ? p.right
                                                : p.x
                                        )}
                                        style={{ position: 'relative' }}
                                        onClick={showTip(isUserAuthenticated ? addToCartToolTip : buyNowToolTip)}
                                    >
                                        <PureTip
                                            timestamp={buyNowTip.timestamp}
                                            placement={buyNowTip.placement}
                                            level={buyNowTip.level}
                                            clickDismiss
                                        >
                                            <Translation id={buyNowTip.message} params={buyNowTip.params} />
                                        </PureTip>
                                        <button
                                            className={cx(
                                                'btn buy-now btn-primary w-100',
                                                buttonsDisabled ? 'disabled' : 'active'
                                            )}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={buttonsDisabled ? buyNowToolTip : null}
                                            disabled={buttonsDisabled || isLoading || cartIsUpdating}
                                            onClick={onBuyNow}
                                        >
                                            <Translation id="Form.Button.BuyNow.Label" />
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {children ? (
                            <div className={`col-${(btnsColSize || 8) - btnsColSize} px-0`}>{children}</div>
                        ) : null}
                    </>
                ) : (
                    <div className="col-12 px-0 d-flex flex-row align-items-center oos">
                        <div className="h-100" style={{ flex: 1 }}>
                            <button className="btn btn-secondary disabled w-100 p-0" disabled>
                                <Translation id="Commerce.Product.ProductOutOfStock" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

ProductInput.propTypes = {
    children: px.node,
    className: px.string,
    isUserAuthenticated: px.oneOfType([px.string, px.bool]),
    minQuantity: px.number,
    onQuantityChange: px.func,
    quantity: px.oneOfType([px.string, px.number]),
    currentChildren: px.arrayOf(px.object),
    checkoutPageLink: px.string,
    onAddToCart: px.func,
    addToCartLabel: px.string,
    currentItemStatus: px.shape({
        hasStock: px.bool,
        statusDisplayName: px.string,
        code: px.string,
    }),
    productInfo: Product,
    gtmListValue: px.string,
    noSidePadding: px.bool,
    hideWhenOOS: px.bool,
    reverseButtonOrder: px.bool,
    numInputButtons: px.bool,
    disabled: px.bool,
    noBuyNow: px.bool,
    allowZeroQty: px.bool,
    paddingValue: px.oneOf([1, 2, 3]),
    statusTooltip: px.oneOf([false, true, undefined, null, 'top', 'bottom', 'left']),
    itemCodes: px.arrayOf(px.string),
    productRecommendationId: px.string,
    totalQty: px.number,
};
