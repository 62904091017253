import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { logger } from 'Common/core';
import { Translation } from 'Common/components/localization';
import { useTranslation, useViewport } from 'Common/hooks';
import { Email } from 'Common/utils';
import { PhoneNumberUtil } from 'google-libphonenumber';

const req = async function mailChimpRequest(email, phone, url, formRef, frameRef) {
    if (url && formRef.current && frameRef.current) {
        const newUrl = new URL(url);

        formRef.current.action = newUrl.href;
        formRef.current.method = 'post';
        formRef.current.target = frameRef.current.name;

        const emailInput = formRef.current.querySelector('input[name="EMAIL"]');
        const phoneInput = formRef.current.querySelector('input[name="PHONE"]');
        const submitInput = formRef.current.querySelector('input[name="submit"]');

        if (emailInput) emailInput.value = email;
        if (phoneInput) phoneInput.value = phone;
        if (submitInput) submitInput.value = 'Submit';

        return new Promise((resolve) => {
            const handleLoad = () => {
                resolve({ success: true });
            };

            const handleError = () => {
                logger.warn('Failed to submit Mailchimp form');
                resolve({ success: false, error: 'Form.Advanced.Subscription.Failure' });
            };

            frameRef.current.onload = handleLoad;
            frameRef.current.onerror = handleError;

            setTimeout(() => {
                formRef.current.submit(); // Submit the form directly
            }, 100);
        });
    } else {
        logger.warn('No URL found provided for AdvancedSubscribeForm block');
    }
    return { success: false };
};

export default function AdvancedSubscribeForm({
    allowMultiple,
    bodyColor,
    bodyText,
    btnColor,
    btnTextColor,
    className,
    id = uuidv4(),
    imageUrl,
    style,
    titleColor,
    url,
    useIcon = false,
}) {
    const viewport = useViewport();
    const idRef = useRef(id);
    const [complete, setComplete] = useState(null);
    const [error, setError] = useState(null);
    const [numError, setNumError] = useState(null);
    const [email, setEmail] = useState('');
    const [num, setNum] = useState('');
    const formRef = useRef(null);
    const frameRef = useRef(null);

    const isValidEmail = useMemo(() => Email.isValid(email || ''), [email]);
    const phoneUtil = PhoneNumberUtil.getInstance();
    const isValidPhone = useMemo(() => {
        setNumError(null);
        if (num.trim() === '') return false;
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(num, 'US'));
        } catch (e) {
            setNumError('Commerce.Account.PhoneNumberValidationMessage');
            return false;
        }
    }, [num, phoneUtil]);

    const title = useTranslation('Form.Advanced.Subscription.Title');
    const lblEmailPlaceholder = useTranslation('Form.Advanced.Subscription.Email.Placeholder');
    const lblPhonePlaceholder = useTranslation('Form.Advanced.Subscription.Phone.Placeholder');
    const submitLbl = useTranslation('Form.Advanced.Subscription.Submit');

    const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);

    const handlePhoneChange = useCallback((e) => setNum(e.target.value), []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            if (isValidEmail || isValidPhone) {
                setComplete(false);
                setError(null);

                const result = await req(email, num, url, formRef, frameRef);

                if (!result?.success) setError(result?.error || 'Form.Advanced.Subscription.Failure');
                setComplete(true);
            } else {
                setError('Form.Advanced.Subscription.Failure');
            }
        },
        [email, num, isValidEmail, isValidPhone, url]
    );

    useEffect(() => {
        const frame = document.createElement('iframe');

        frame.name = `frame_${idRef.current}`;
        frame.style.display = 'none';
        frameRef.current = frame;
        document.body.appendChild(frame);

        return () => {
            document.body.removeChild(frame);
        };
    }, []);

    return (
        <>
            <div className={cx('AdvancedSubscribeForm', 'row', 'mx-2', className)} style={style} id={idRef.current}>
                {allowMultiple || !complete || error ? (
                    <>
                        {!viewport.is.lt('md') ? (
                            <div className="d-flex col-md-6 justify-content-center align-items-center">
                                <img className="mw-100 object-fit-contain pl-2" alt={title} src={imageUrl} />
                            </div>
                        ) : null}
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                className="d-flex flex-column align-items-center subscription__form needs-validation"
                            >
                                <label
                                    className={cx(
                                        'pb-1 pb-lg-0 d-flex justify-content-center subheader',
                                        `text__color-${titleColor}`
                                    )}
                                >
                                    <Translation id="Form.Advanced.Subscription.Title" />
                                </label>
                                <p className={('mt-2 mb-4', `text__color-${bodyColor}`)}>{bodyText}</p>
                                {viewport.is.lt('md') ? (
                                    <img className="mw-100 object-fit-contain mb-2" alt={title} src={imageUrl} />
                                ) : null}
                                <div className="w-100 d-flex row">
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="w-100 my-1 mx-sm-1 mx-md-0 mx-lg-1"
                                            placeholder={lblEmailPlaceholder}
                                            autoComplete="email"
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <input
                                            type="tel"
                                            value={num}
                                            onChange={handlePhoneChange}
                                            className="w-100 my-1 mx-sm-1 mx-md-0 mx-lg-1"
                                            placeholder={lblPhonePlaceholder}
                                            autoComplete="tel"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center pt-2">
                                    <button
                                        type="submit"
                                        className={cx('btn', `btn-${btnColor}`, `text__color-${btnTextColor}`)}
                                        disabled={!isValidEmail && !isValidPhone}
                                        aria-label={submitLbl}
                                    >
                                        {useIcon ? (
                                            <i className="fa fa-arrow-right"></i>
                                        ) : (
                                            <Translation id="Form.Advanced.Subscription.Submit" />
                                        )}
                                    </button>
                                    {numError ? (
                                        <div className="col form-messagebox alert alert-danger text-center">
                                            <Translation id={numError} />
                                        </div>
                                    ) : null}
                                </div>
                            </form>
                        </div>
                    </>
                ) : null}
            </div>
            {complete ? (
                <div className="MailingListSignup__message d-flex justify-content-center align-items-center px-3">
                    {error ? (
                        <div className="col form-messagebox alert alert-danger text-center">
                            <Translation id={error} />
                        </div>
                    ) : (
                        <div className="col form-messagebox alert alert-custom text-center">
                            <Translation id="Form.Advanced.Subscription.Success" />
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
}

AdvancedSubscribeForm.propTypes = {
    allowMultiple: px.bool,
    bodyColor: px.string,
    bodyText: px.string,
    btnColor: px.string,
    btnTextColor: px.string,
    className: px.string,
    id: px.string,
    imageUrl: px.string,
    style: px.any,
    titleColor: px.string,
    url: px.string,
    useIcon: px.bool,
};
